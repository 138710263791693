import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute  } from '@angular/router';
import { DataService } from '../data.service';
import { ConfigService } from '../config.service';
import { CommonService } from '../common.service';
import { LocalizationService } from '../localization.service';
import { SnackBarService } from "../snack-bar.service";

enum NoTenantApplication {
    Monitoring = 'monitoring',
    Onp = 'onp',
    Bwconfig = 'bwconfig'
} 

@Component({
    selector: 'app-callout',
    templateUrl: './callout.component.html',
    styleUrls: ['./callout.component.scss']
})

export class CalloutComponent implements OnInit {
    constructor(
        private router: Router,
        private data: DataService,
        private config: ConfigService,
        private common: CommonService,
        private route: ActivatedRoute,
        private localization: LocalizationService,
        private snack: SnackBarService,
        private cdRef : ChangeDetectorRef
    ) {
        common.title = this.tr('access.callout.redirect.title');
        common.clearError();
    }
    error = '';
    //mutipleRealms = false;
    realms = false;
    selectedRealm = '';
    closeSessions = false;
    ssoRedirection = true;
    loginError = false;
    error_description = '';
    isOpsUser = false;
    opsPool = '';
    opsTenantsErrorCallout = false;
    
    accessRedirect() {
        var amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));
        var origin = amplifyInfo.origin;
        let application = amplifyInfo.application?.toLowerCase();       
        if (amplifyInfo.isOpsUser && (amplifyInfo.opsPool?.toUpperCase() === "BASWARE" || amplifyInfo.opsPool?.toUpperCase() === "PARTNER")) {  
            let opsParameter = amplifyInfo.opsParameter;      
            if(application && application !== null && application !== '') {
                if(application == 'resetmfa') { 
                    this.data.sendData(false);
                    this.router.navigate(['/resetmfa']);
                }
                else {                
                    let appExists = Object.values(NoTenantApplication).includes(application as NoTenantApplication);
                    
                    if(appExists) {
                        this.validateAndRedirect(origin);
                    } else {
                        this.data.sendData(true);
                        this.router.navigate(['/applications'], { queryParams: { ops: opsParameter } });
                    } 
                }
            }
            else {
                this.data.sendData(true);
                this.router.navigate(['/applications'], { queryParams: { ops: opsParameter } });
            }            
        }
        else if (origin && this.config.isAllowedToRedirect(origin)) {
            location.href = origin;
        }
        else if(application && application == 'cloudscan') {
            this.router.navigate(['/applications?application='+application]);
        }
        else {            
            this.router.navigate(['/applications']);
        }
    }

    validateAndRedirect(origin) {
        if (origin && this.config.isAllowedToRedirect(origin)) {
            location.href = origin;
        }
    }

    async ngOnInit() {
        let errorDescription = this.config.getParameterByName("error_description")
        let amplifyInfo = JSON.parse(localStorage.getItem('amplifyInfo'));

        if (!!errorDescription) {
            this.ssoRedirection = false;
            this.error_description = decodeURIComponent(errorDescription);
            this.common.title = this.tr("access.callout.accessDeniedTitle");
            this.loginError = true;
        }
        else {
            if(amplifyInfo){                
                let isOpsUser = amplifyInfo.isOpsUser;
                if (isOpsUser) {
                    this.isOpsUser = isOpsUser;
                    this.opsPool = amplifyInfo.opsPool.toString()
                }

                try {
                    var result = await this.data.getToken(amplifyInfo.tokenUrl, amplifyInfo.user_pool, amplifyInfo.client_id, amplifyInfo.session_count, this.config.getParameterByName("code"), this.isOpsUser, this.opsPool);
                    if(result == false){
                        this.closeSessions = true;
                        this.common.title = this.tr("access.closeSessions.title");
                    }
                    else {
                        this.closeSessions = false;
                        if(this.opsPool.toUpperCase() != "BASWARE" && this.opsPool.toUpperCase() != "PARTNER")
                            this.config.setCookie('AccessRealm', result[0].realm_id, 1);
                        this.accessRedirect();                      
                    }
                }
				catch(e) {                    
                    if(this.data.isBackButtonClickedOnApplicationsPage) {
                        this.accessRedirect();
                    }                        
                    this.ssoRedirection = false;
                    if(this.isOpsUser && (this.opsPool.toUpperCase() == "BASWARE" || this.opsPool.toUpperCase() == "PARTNER")){
                        this.data.sendData(true);
                        this.opsTenantsErrorCallout = true;
                    }
                }
            }
        }
    }

    async closeActiveSessions() {

        var result = await this.data.closeActiveSessions(this.data.accesstoken);
        if(result.body){

            this.closeSessions = false;
            let postResult = await this.data.postSessionForSamlUser(this.isOpsUser, this.opsPool);
            if(this.opsPool.toUpperCase() != "BASWARE" && this.opsPool.toUpperCase() != "PARTNER")
                this.config.setCookie('AccessRealm', postResult[0].realm_id, 1);
            this.accessRedirect();
        }
        else{
            this.router.navigate(['/logout']);
        };
    }

    async copyErrorDescription() {
        //copy error message to clipboard
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.error_description;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.snack.openSnackBar(this.tr('access.callout.error.description.copied.message'), 'info');
    }

    buttonText() {
        if(this.closeSessions)
            return this.tr("access.closeSessions.closeActiveSessions");
        else if(this.loginError)
            return this.tr("access.callout.loginErrorCopyErrorDescription");
    }

    tr(text: string) {
        return this.localization.translate(text);
    }
}
